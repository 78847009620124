const dotenv = require('dotenv');
dotenv.config();
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import VueSession from 'vue-session';
import VueApollo from "vue-apollo";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
//import "font-awesome/css/font-awesome.css";
import DashboardPlugin from './plugins/dashboard-plugin';
import ScreenSize from 'screen-size-vue';


Vue.config.productionTip = false
const link = new HttpLink({
  uri: process.env.VUE_APP_SERVER_URL,
  headers:{
    authorization: process.env.VUE_APP_TOKEN,
    id: window.location.host
    // id:"service.app360.my"
  }
});
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true
  })
});

Vue.use(VueSession);
Vue.use(VueApollo);
Vue.use(Antd);
Vue.use(DashboardPlugin);
Vue.use(ScreenSize)

const apolloProvider = new VueApollo({
  defaultClient: client,
})

new Vue({
  vuetify,
  apolloProvider,
  router,
  render: h => h(App)
}).$mount('#app')

import 'vue2-dropzone/dist/vue2Dropzone.min.css'