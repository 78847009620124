<template>
  <v-app>
  <Topbar></Topbar>
  <div
    style="background-image: url('/img/form-banner.jpeg');
    height: 200px;
    z-index: 2;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #eee;"
  ></div>

  <div v-if="$data.$screenSize.width > 765">
    <b-img :src="image" rounded="sm" 
      style="width:150px;height:150px;
        position: absolute;
        left: 50px;
        border-radius: 7px;
        z-index: 3;
        bottom: 0;
        top:180px;
        background: #ffffff00;"
    />

    <div style="position:absolute; left:250px; top: 270px">
      <p class="h1">Oasis Beauty Spa</p>
      <p>Beauty and Wellness Salon</p>
    </div>
  </div>

  <div v-else>
    <b-img :src="image" rounded="sm" 
      style="width:120px;height:120px;
        position: absolute;
        border-radius: 7px;
        z-index: 3;
        bottom: 0;
        top:200px;
        background: #ffffff00;" 
        :style="{left:($data.$screenSize.width-120)/2 + 'px'}"
    />

    <div 
      style="text-align:center; padding-top:70px"
    >
      <p class="h1">Oasis Beauty Spa</p>
      <p>Beauty and Wellness Salon</p>
    </div>
  </div>
  
  <div v-if="$data.$screenSize.width > 765" style="padding-top:100px"></div>
  <div  style="margin:0px 20px 0px 20px;">
      <b-form 
        ref="form"
        lazy-validation
      >
      <b-card class="formCard" v-if="$data.$screenSize.width > 765"
      >
      <b-row>
        <b-col cols="12" v-for="(field,n) of steps" :key="n">
          <b-card-text>
            <b-alert dismissible variant="danger" v-model="alert.show" @dismissed="dismiss">
              <span class="alert-text">{{ alert.message }}</span>
            </b-alert>
            <div v-if="field.type === 'Outlet'">
              <b-button variant="flat" class="button-collapse"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-1"
                @click="openField(n)"
              >
              <div>
              <span>Outlet</span>
              <span v-if="bookingById.outlet_id">: {{ findName(field) }}</span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-1" v-model="field.isOpen">
                <b-card>
                  <select class="form-control" style="width:50%"
                    v-model="bookingById.outlet_id"
                    :items="processed_outlets"
                    item-value="id"
                    item-text="name"
                    label="Outlet"
                    value=""
                    @change="processedDate();outletImage();validateField(field,n);"
                  >
                    <option value="">Any {{field.type}}</option>
                    <option
                      v-for="(field,n) in processed_outlets"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.name}}
                    </option>
                  </select>
                  <b-row class="mt-2">
                    <b-col cols="4" v-if="outlet_image" class="outletImage">
                      <img 
                    class="image"
                    :src="outlet_image"
                  />
                    </b-col>
                    <b-col :cols="outlet_image? 8:12">
                      <span class="outletInformation" v-if="outletInformation.fullAddress">Address: {{ outletInformation.fullAddress }}</span>
                      <span class="outletInformation" v-if="outletInformation.contact"><a :href="'tel:'+outletInformation.contact">Contact: {{ outletInformation.contact }}</a></span>
                      <span class="outletInformation" v-if="outletInformation.email"><a :href="'mailto:'+outletInformation.email">Email: {{ outletInformation.email }}</a></span>
                    </b-col>
                  </b-row>
                </b-card>
              </b-collapse>
            </div>

            <div v-else-if=" field.type =='Staff' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-2"
                @click="openField(n)"
              >
              <div>
                <span>Staff</span>
                <span v-if="bookingById.staff_id">: {{ findName(field) }}</span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-2" v-model="field.isOpen">
                <b-card> 
                  <select class="form-control" style="width:50%"
                    v-model="bookingById.staff_id"
                    :items="processed_staffs"
                    item-value="id"
                    item-text="name"
                    label="Staff"
                    @change="processedServices();processedDate();validateField(field,n);;"
                    value=""
                  >
                    <option value="">Any {{field.type}}</option>
                    <option
                      v-for="(field,n) in processed_staffs"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.fullname}}</option>
                  </select>
                </b-card>
              </b-collapse>
            </div>

            <div v-else-if=" field.type =='Service Type' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-3"
                @click="openField(n)"
              >
              <div>
                <span>Service Type</span>
                <span  v-if="bookingById.service_type_id">: {{ findName(field) }}</span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-3" v-model="field.isOpen">
                <b-card> 
                  <select class="form-control" style="width:50%"
                    v-model="bookingById.service_type_id"
                    :items="serviceTypes"
                    item-value="id"
                    item-text="name"
                    label="ServiceType"
                    @change="validateField(field,n);"
                    return-object
                    value=""
                    >
                      <option value="">- Select {{field.type}} -</option>
                      <option
                       v-for="(field,n) in serviceTypes"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.name}}</option>
                  </select>
                </b-card>
              </b-collapse>
            </div>

            <div v-else-if=" field.type =='Service' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-4"
                @click="openField(n)"
              >
              <div>
                <span>Service</span>
                <span v-if="bookingById.service_id">: {{ findName(field) }} </span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-4" v-model="field.isOpen">
                <b-card> 
                  <select class="form-control" style="width:50%"
                    v-model="bookingById.service_id"
                    :items="processed_services"
                    item-value="id"
                    item-text="name"
                    label="Service"
                    @change="setServiceName();serviceImage();validateField(field,n);"
                    return-object
                    value=""
                    >
                      <option value="">- Select {{field.type}} -</option>
                      <option
                      v-for="(field,n) in processed_services"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.name}} ({{field.duration}} minutes) - RM {{ field.price }}</option>
                  </select>
                  <img 
                    v-if="service_image"
                    class="image"
                    :src="service_image"
                  />
                </b-card>
              </b-collapse>
            </div>

            <div v-else-if=" field.type =='Resource Type' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-5"
                @click="openField(n)"
              >
              <div>
                <span>Resource Type</span>
                <span  v-if="bookingById.service_type_id">: {{ findName(field) }}</span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-5" v-model="field.isOpen">
                <b-card>
                  <select class="form-control" style="width:50%"
                    v-model="bookingById.resource_type_id"
                    :items="processed_resource_types"
                    item-value="id"
                    item-text="name"
                    label="Resource Type"
                    @input="setResourceTypeName"
                    return-object
                    value=""
                  >
                    <option value="">- Select {{field.type}} -</option>
                    <option
                      v-for="(field,n) in processed_resource_types"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.name}}</option>
                  </select>
                </b-card>
              </b-collapse>
            </div>

            <div v-else-if=" field.type =='Date' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-6"
                @click="openField(n)"
              >
                <div>
                  <span>Date</span>
                  <span v-if=" bookingById.booked_date">: {{ bookingById.booked_date }}</span>
                </div>
                <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
                </b-button>
                <div>
                  <b-collapse id="collapse-6" v-model="field.isOpen">
                    <b-card> 
                      <b-calendar 
                        locale="en"
                        selected-variant="secondary"
                        today-variant="primary"
                        nav-button-variant="primary" 
                        v-model="bookingById.booked_date"
                        :min="min"
                        :date-disabled-fn="disableDate"
                        block
                        @input="processedTimeSlot();validateField(field,n);"
                        >
                      </b-calendar>
                    </b-card>
                  </b-collapse>
                </div>
              </div>

              <div v-else-if=" field.type =='Time'">
                <b-button variant="flat" class="button-collapse text-left"
                  :aria-expanded="field.isOpen"
                  aria-controls="collapse-7"
                  @click="openField(n)"
                >
                  <div>
                    <span>Time</span>
                    <span v-if=" bookingById.from_time">: {{ bookingById.from_time }}</span>
                  </div>
                  <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
                  </b-button>
                  <b-collapse id="collapse-7" v-model="field.isOpen">
                  <b-card>
                    <b-form-radio-group
                      v-model="bookingById.from_time"
                      :options="timeslots"
                      button-variant="secondary"
                      buttons
                      @change="validateField(field,n)"
                    ></b-form-radio-group>
                  </b-card>
                </b-collapse>
              </div>

              <div v-else-if="field.type == 'DateTime'">
                <b-button variant="flat" class="button-collapse text-left" :aria-expanded="field.isOpen" aria-controls="collapse-8" @click="openField(n)">
                <div class="datetime">
                  <span>DateTime</span>
                  <span v-if="bookingById.booked_date">: {{ bookingById.booked_date }}</span>
                  <span v-if="bookingById.from_time">: {{ bookingById.from_time }}</span>
                </div>
                <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
                  </b-button>
                <div class="calendar-container">
                  <b-collapse id="collapse-8" v-model="field.isOpen">
                    <b-card>
                      <b-row>
                        <b-col cols="7">
                        <b-calendar 
                          locale="en" 
                          selected-variant="secondary" 
                          today-variant="primary" 
                          nav-button-variant="primary" 
                          v-model="bookingById.booked_date"  
                          :min="min" 
                          :date-disabled-fn="disableDate"
                          block
                          @input="processedTimeSlot(); validateField(field, n);">
                        </b-calendar>
                        </b-col>
                        <b-col cols="5">
                          <b-form-radio-group 
                          v-model="bookingById.from_time" 
                          :options="timeslots" 
                          button-variant="secondary"
                          buttons 
                          @change="validateField(field, n)">
                          </b-form-radio-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-collapse>
                </div>
              </div>

              <div v-else-if=" field.type =='Info' ">
                <b-button variant="flat" class="button-collapse text-left"
                  :aria-expanded="field.isOpen"
                  aria-controls="collapse-9"
                  @click="openField(n)"
                >
                  Info
                <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
                </b-button>
                <b-collapse id="collapse-9" v-model="field.isOpen">
                  <b-card>
                      <p class="m-2">Phone Number</p>
                      <div class="infoContainer">
                        <b-form-input class="m-2" 
                          v-model="phoneNumber"
                          :counter="counterLength"
                          label="Phone number"
                          placeholder="60123456789"
                          >
                        </b-form-input>
                        <b-button 
                          variant="dark"
                          @click="checkUserPhoneNumberExists(field,n)"
                          >
                            Check
                        </b-button>
                      </div>
                    <div v-if="!showNameAndPw">
                      <div class="m-2">Fullname: {{ bookingById.user_fullname }}</div>
                      <div class="m-2">Contact: {{ bookingById.user_contact }}</div>
                    </div>
                    <div v-if="showNameAndPw">
                      <P class="m-2">Username</P>
                      <b-form-input
                        style="width:50%"
                        v-model="bookingById.user_fullname"
                        :disabled="!newUser"
                        placeholder="Name"
                        required>
                      </b-form-input>
                      <p class="m-2" v-if="showNameAndPw" style="color: red;">Default name will be phone number</p> 
                      <P class="m-2">Password</P>
                      <div class="iconContainer">
                        <b-form-input
                          class="mr-2"
                          style="width:50%"
                          v-if="newUser"
                          v-model="password"
                          :type="showPassword ? 'text' : 'password'"
                          label="Password"
                          placeholder="Password"
                          required
                        ></b-form-input>
                        <b-icon
                          :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                          @click="showPassword = !showPassword">
                        </b-icon>
                      </div>
                      <p class="m-2" v-if="newUser" style="color: red;">Default password will be phone number last 6 digits</p> 
                    </div>
                    <b-button 
                      variant="dark"
                      class="m-2"
                      v-if="showNameAndPw"
                      @click="createUser(field,n)"
                    >
                      Create
                    </b-button>
                    <p class="m-2" v-if="invalidPhone" style="color: red;">Invalid Phone Number</p>
                    <div class="button-collapse m-2" v-if="suitablePackageList.length > 0">
                      <p class="mb-0 ml-2" v-if="bookingById.package_id">{{ bookingById.package_name }}</p>
                      <p class="mb-0 ml-2" v-else>You have {{ suitablePackageList.length }} suitable package(s)!</p>
                      <b-button 
                      variant="dark"
                      class="m-2"
                      v-if="!bookingById.package_id"
                      @click="$refs['my-modal'].show()"
                    >
                      Apply
                    </b-button>
                    <b-button 
                      variant="dark"
                      class="m-2"
                      v-if="bookingById.package_id"
                      @click="removeVoucher"
                    >
                      Reset
                    </b-button>
                    </div>
                  </b-card>
                </b-collapse>
              </div>
          </b-card-text>
        </b-col>
        </b-row>
      </b-card>
      <b-card class="cardValue" v-if="$data.$screenSize.width > 765"
      >
      <div class="p-1">Outlet: {{ findName({ type: 'outlet' }) }}</div>
        <div class="p-1">Staff: {{ findName({ type: 'staff' }) }}</div>
        <div class="p-1">Service Type: {{ findName({ type: 'service type' }) }}</div>
        <div class="p-1">Service: {{ findName({ type: 'service' })  }}</div>
          <div class="p-1">
            <label class="m-1">Subtotal:</label>
            <b-input-group 
              prepend="RM">
              <b-form-input v-model="servicePrice.subtotal"
              type="number"
              min="0"
              readonly>
              </b-form-input>
              </b-input-group>
          </div>
          <div class="p-1" v-for="t of servicePrice.taxList" :key="t.id">
            <label class="m-1">{{ t.name }}({{ t.percentage }}%):</label>
            <b-input-group 
              prepend="RM">
              <b-form-input v-model="t.amount"
              type="number"
              min="0"
              readonly>
              </b-form-input>
            </b-input-group>
          </div>
          <div class="p-1">
            <label class="m-1">Total:</label>
            <b-input-group 
              prepend="RM">
              <b-form-input v-model="servicePrice.total"
              type="number"
              min="0"
              readonly>
              </b-form-input>
              </b-input-group>
          </div>

        <div class="p-1">Date: {{ bookingById.booked_date }}</div>
        <div class="p-1">Time: {{ bookingById.from_time }}</div>
        <div class=p-1>DateTime: {{ bookingById.booked_date }} {{ bookingById.from_time }}</div>
      </b-card>
      <div class="w-75 pb-4 pl-4" v-if="$data.$screenSize.width > 765">
        <b-button 
          variant="primary"
          @click="validate"
        >
          Book Appoinment
        </b-button>
        <b-alert
          v-model="showAlert"
          class="position-fixed fixed-bottom m-0 rounded-0"
          style="z-index: 2000;"
          variant="warning"
          
          dismissible
        >
          Field type is required.
        </b-alert>
        <b-button 
          variant="primary"
          @click="resetField()"
        >
          Reset
        </b-button>
      </div>

        <b-card v-else class="pb-4">
          <b-card-text v-for="(field,n) of steps" :key="n">

            <div v-if="field.type === 'Outlet'">
              <b-button variant="flat" class="button-collapse"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-1"
                @click="openField(n)"
              >
              <div>
              <span>Outlet</span>
              <span v-if="bookingById.outlet_id">: {{ findName(field) }}</span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-1" v-model="field.isOpen">
                <b-card>
                  <select class="form-control"
                    v-model="bookingById.outlet_id"
                    :items="processed_outlets"
                    item-value="id"
                    item-text="name"
                    label="Outlet"
                    @change="processedDate();outletImage();validateField(field,n);"
                  >
                    <option value="">Any {{field.type}}</option>
                    <option
                      v-for="(field,n) in processed_outlets"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.name}}
                    </option>
                  </select>
                  <b-row class="mt-2">
                    <b-col cols="12" v-if="outlet_image" class="outletImage d-flex justify-content-center">
                      <img 
                        class="image"
                        :src="outlet_image"
                      />
                    </b-col>
                    <b-col cols="12">
                      <span class="outletInformation" v-if="outletInformation.fullAddress">Address: {{ outletInformation.fullAddress }}</span>
                      <span class="outletInformation" v-if="outletInformation.contact"><a :href="'tel:'+outletInformation.contact">Contact: {{ outletInformation.contact }}</a></span>
                      <span class="outletInformation" v-if="outletInformation.email"><a :href="'mailto:'+outletInformation.email">Email: {{ outletInformation.email }}</a></span>
                    </b-col>
                  </b-row>
                </b-card>
              </b-collapse>
            </div>

            <div v-else-if=" field.type =='Staff' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-2"
                @click="openField(n)"
              >
              <div>
                <span>Staff</span>
                <span v-if="bookingById.staff_id">: {{ findName(field) }}</span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-2" v-model="field.isOpen">
                <b-card> 
                  <select class="form-control"
                    v-model="bookingById.staff_id"
                    :items="processed_staffs"
                    item-value="id"
                    item-text="fullname"
                    label="Staff"
                    @change="processedServices();processedDate();validateField(field,n);;"
                  >
                    <option value="">Any {{field.type}}</option>
                    <option
                      v-for="(field,n) in processed_staffs"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.fullname}}</option>
                  </select>
                </b-card>
              </b-collapse>
            </div>

            <div v-else-if=" field.type =='Service Type' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-4"
                @click="openField(n)"
              >
              <div>
                <span>Service Type</span>
                <span  v-if="bookingById.service_type_id">: {{ findName(field) }}</span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-4" v-model="field.isOpen">
                <b-card> 
                  <select class="form-control"
                    v-model="bookingById.service_type_id"
                    :items="serviceTypes"
                    item-value="id"
                    item-text="name"
                    label="ServiceType"
                    @change="validateField(field,n);"
                    return-object
                    >
                      <option value="">- Select {{field.type}} -</option>
                      <option
                       v-for="(field,n) in serviceTypes"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.name}}</option>
                  </select>
                </b-card>
              </b-collapse>
            </div>

            <div v-else-if=" field.type =='Service' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-3"
                @click="openField(n)"
              >
              <div>
                <span>Service</span>
                <span v-if="bookingById.service_id">: {{ findName(field) }}</span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-3" v-model="field.isOpen">
                <b-card> 
                  <select class="form-control"
                    v-model="bookingById.service_id"
                    :items="processed_services"
                    item-value="id"
                    item-text="name"
                    label="Service"
                    @change="setServiceName();serviceImage();validateField(field,n);"
                    return-object
                    value=""
                    >
                      <option value="">- Select {{field.type}} -</option>
                      <option
                      v-for="(field,n) in processed_services"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.name}} ({{field.duration}} minutes) - RM {{ field.price }}</option>
                  </select>
                  <img 
                    v-if="service_image"
                    class="image"
                    :src="service_image"
                  />
                </b-card>
              </b-collapse>
            </div>
            
            <div v-else-if=" field.type =='Resource Type' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-5"
                @click="openField(n)"
              >
              <div>
                <span>Resource Type</span>
                <span  v-if="bookingById.service_type_id">: {{ findName(field) }}</span>
              </div>
              <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
              </b-button>
              <b-collapse id="collapse-5" v-model="field.isOpen">
                <b-card>
                  <select 
                    class="form-control" 
                    style="width:50%"
                    v-model="bookingById.resource_type_id"
                    :items="processed_resource_types"
                    item-value="id"
                    item-text="name"
                    label="Resource Type"
                    @input="setResourceTypeName"
                    return-object
                    value=""
                  >
                    <option value="">- Select {{field.type}} -</option>
                    <option
                      v-for="(field,n) in processed_resource_types"
                      :key="`${n+1}-content`"
                      :value="field.id"
                    > {{field.name}}</option>
                  </select>
                </b-card>
              </b-collapse>
            </div>

            <div v-else-if=" field.type =='Date' ">
              <b-button variant="flat" class="button-collapse text-left"
                :aria-expanded="field.isOpen"
                aria-controls="collapse-6"
                @click="openField(n)"
              >
                <div>
                  <span>Date</span>
                  <span v-if=" bookingById.booked_date">: {{ bookingById.booked_date }}</span>
                </div>
                <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
                </b-button>
                <div>
                  <b-collapse id="collapse-6" v-model="field.isOpen">
                    <b-card> 
                      <b-calendar 
                        locale="en"
                        selected-variant="secondary"
                        today-variant="primary"
                        nav-button-variant="primary" 
                        v-model="bookingById.booked_date"
                        :min="min"
                        :date-disabled-fn="disableDate"
                        @input="processedTimeSlot();validateField(field,n);"
                        >
                      </b-calendar>
                    </b-card>
                  </b-collapse>
                </div>
              </div>

              <div v-else-if=" field.type =='Time'">
                <b-button variant="flat" class="button-collapse text-left"
                  :aria-expanded="field.isOpen"
                  aria-controls="collapse-7"
                  @click="openField(n)"
                >
                  <div>
                    <span>Time</span>
                    <span v-if=" bookingById.from_time">: {{ bookingById.from_time }}</span>
                  </div>
                  <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
                  </b-button>
                  <b-collapse id="collapse-7" v-model="field.isOpen">
                  <b-card>
                    <b-form-radio-group
                      v-model="bookingById.from_time"
                      :options="timeslots"
                      button-variant="secondary"
                      buttons
                      @change="validateField(field,n)"
                    ></b-form-radio-group>
                  </b-card>
                </b-collapse>
              </div>

              <div v-else-if="field.type == 'DateTime'">
                <b-button variant="flat" class="button-collapse text-left" 
                :aria-expanded="field.isOpen" 
                aria-controls="collapse-8" 
                @click="openField(n)">
                <div>
                  <span>DateTime</span>
                  <span v-if="bookingById.booked_date">: {{ bookingById.booked_date }}</span>
                  <span v-if="bookingById.from_time">: {{ bookingById.from_time }}</span>
                </div>
                  <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
                </b-button>
                <div>
                  <b-collapse id="collapse-8" v-model="field.isOpen">
                    <b-card>
                      <b-row>
                        <b-col>
                        <b-calendar 
                          locale="en" 
                          selected-variant="secondary" 
                          today-variant="primary" 
                          nav-button-variant="primary" 
                          v-model="bookingById.booked_date"  
                          :min="min" 
                          :date-disabled-fn="disableDate"
                          @input="processedTimeSlot(); validateField(field, n);">
                        </b-calendar>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-radio-group 
                          v-model="bookingById.from_time" 
                          :options="timeslots" 
                          button-variant="secondary"
                          buttons @change="validateField(field, n)">
                          </b-form-radio-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-collapse>
                </div>
              </div>

              <div v-else-if=" field.type =='Info' ">
                <b-button variant="flat" class="button-collapse text-left"
                  :aria-expanded="field.isOpen"
                  aria-controls="collapse-5"
                  @click="openField(n)"
                >
                  Info
                <b-icon :icon="field.isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
                </b-button>
                <b-collapse id="collapse-5" v-model="field.isOpen">
                  <b-card>
                      <p class="m-2">Phone Number</p>
                      <div>
                        <b-form-input class="m-2" 
                          v-model="phoneNumber"
                          :counter="counterLength"
                          label="Phone number"
                          placeholder="60123456789"
                          >
                        </b-form-input>
                        <b-button
                          class="m-2" 
                          variant="dark"
                          @click="checkUserPhoneNumberExists(field,n)"
                          >
                            Check
                        </b-button>
                      </div>
                    <div v-if="!showNameAndPw">
                      <div class="m-2">Fullname: {{ bookingById.user_fullname }}</div>
                      <div class="m-2">Contact: {{ bookingById.user_contact }}</div>
                    </div>
                    <div v-if="showNameAndPw">
                      <P class="m-2">Username</P>
                      <b-form-input
                        v-model="bookingById.user_fullname"
                        :disabled="!newUser"
                        placeholder="Name"
                        >
                      </b-form-input>
                      <p class="m-2" v-if="showNameAndPw" style="color: red;">Default name will be phone number</p> 
                      <P class="m-2">Password</P>
                      <div class="iconContainer">
                        <b-form-input
                          class="mr-2"
                          v-if="newUser"
                          v-model="password"
                          :type="showPassword ? 'text' : 'password'"
                          label="Password"
                          placeholder="Password"
                          :rules="passwordRules"
                          required
                        ></b-form-input>
                        <b-icon
                          :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                          @click="showPassword = !showPassword">
                        </b-icon>
                      </div>
                      <p class="m-2" v-if="newUser" style="color: red;">Default password will be phone number last 6 digits</p> 
                    </div>
                    <b-button 
                      variant="dark"
                      class="m-2"
                      v-if="showNameAndPw"
                      @click="createUser(field,n)"
                    >
                      Create
                    </b-button>
                    <p class="m-2" v-if="invalidPhone" style="color: red;">Invalid Phone Number</p>
                    <div class="m-2" v-if="suitablePackageList.length > 0">
                      <hr class="my-2">
                      <p v-if="bookingById.package_id">Package Applied: <br>{{ bookingById.package_name }}</p>
                      <p v-else>You have {{ suitablePackageList.length }} suitable package(s)!</p>
                      <b-button 
                      variant="dark"
                      class=""
                      v-if="!bookingById.package_id"
                      @click="$refs['my-modal'].show()"
                    >
                      Apply
                    </b-button>
                    <b-button 
                      variant="dark"
                      class=""
                      v-if="bookingById.package_id"
                      @click="removeVoucher"
                    >
                      Reset
                    </b-button>
                    </div>
                  </b-card>
                </b-collapse>
              </div>
          </b-card-text>
        </b-card>
        <div v-if="$data.$screenSize.width < 765">
          <b-card>
            <div class="p-1">Outlet: {{ findName({ type: 'outlet' }) }}</div>
            <div class="p-1">Staff: {{ findName({ type: 'staff' }) }}</div>
            <div class="p-1">Service Type: {{ findName({ type: 'service type' }) }}</div>
            <div class="p-1">Service: {{ findName({ type: 'service' })  }}</div>
              <div class="p-1">
                <label class="m-1">Subtotal:</label>
                <b-input-group 
                  prepend="RM">
                  <b-form-input v-model="servicePrice.subtotal"
                  type="number"
                  min="0"
                  readonly>
                  </b-form-input>
                  </b-input-group>
              </div>
              <div class="p-1" v-for="t of servicePrice.taxList" :key="t.id">
                <label class="m-1">{{ t.name }}({{ t.percentage }}%):</label>
                <b-input-group 
                  prepend="RM">
                  <b-form-input v-model="t.amount"
                  type="number"
                  min="0"
                  readonly>
                  </b-form-input>
                </b-input-group>
              </div>
              <div class="p-1">
                <label class="m-1">Total:</label>
                <b-input-group 
                  prepend="RM">
                  <b-form-input v-model="servicePrice.total"
                  type="number"
                  min="0"
                  readonly>
                  </b-form-input>
                  </b-input-group>
              </div>

            <div class="p-1">Date: {{ bookingById.booked_date }}</div>
            <div class="p-1">Time: {{ bookingById.from_time }}</div>
            <div class=p-1>DateTime: {{ bookingById.booked_date }} {{ bookingById.from_time }}</div>
          </b-card>
          <div class="m-2">
            <b-button 
            variant="primary"
            block
            @click="validate"
            >
              Book Appoinment
            </b-button>
          </div>
          <div class="m-2">
            <b-button 
            variant="primary"
            block
            @click="resetField()"
            >
              Reset
            </b-button>
          </div>
        </div>
      </b-form>
  </div>
  <template>
    <b-modal ref="my-modal" centered scrollable  title="Select Package's Service"
    @hidden="remainVoucher"
    @ok="useVoucher"
    >
        <div>
          <b-form-group>
            <b-form-radio-group
              buttons
              stacked
              name="radio-btn-stacked"
              v-model="selectedPackage"
            >
              <b-form-radio v-for="(p, index) of suitablePackageList" :key="index" :value="p" style="text-align: left;">
                <p class="m-0">{{ p.Package.package_name }}</p>
                <span v-if="p.service">{{p.service_Type.name}}({{p.service.name}})</span>
                <span v-if="p.service_Type && !p.service">{{p.service_Type.name}}</span>
                <span v-if="p.resource">{{p.resource_Type.name}}({{p.resource.name}})</span>
                <span v-if="p.resource_Type && !p.resource">{{p.resource_Type.name}}</span>
                <p class="m-0">Expiry Date: {{ formatDate(p.expiration_date) }}</p>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </b-modal>
    </template>
  </v-app>
</template>

<style>
  .image{
    max-width: 200px;
    max-height: 200px;
    padding: 5px;
  }
  .button-collapse{
    margin:4px;
    width:100%;
    border: 1px solid grey !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .calendar-container{
    min-width: 270px;
  }
  .btn-group{
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
  }
  .btn-group > .btn{
    width:50%;
    flex:none !important;
    margin-left:0 !important;
    border-radius:unset !important;
    overflow:hidden;
    padding:10px;
  }
  .datetime {
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
  .infoContainer{
    width:50%;
    display:flex;
    align-items:center;
  }
  .iconContainer{
    display:flex;
    align-items:center;
  }
  .cardValue{
    float:right;
    width:25%;
    right:2.5%;
    position:fixed !important;
    z-index:2;
    bottom:3%;
  }
  .formCard{
    float:left;
    width:70%;
    margin: 5px;
    margin-left: 0px;
  }
  .cardForm{
    margin: 5px;
    margin-left: 0px;
  }
  .image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .outletInformation {
    display:flex;
  }
  .outletImage {
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
  }
  .v-application--wrap{
    height: 100%;
  }
</style>

<script>
  import gql from 'graphql-tag'
	import Datepicker from 'vuejs-datepicker';
  import Topbar from '/src/custom-components/Topbar'

  const GET_SETTINGS_QUERY = gql`
      query settings {
        settings {
          slot_duration
          online_package
          image_id
        }
      }
  `
  const GET_BOOKING_FLOW_QUERY = gql`
      query bookingflow {
        bookingflow {
          type
          required
        }
      }
  `
	const GET_ALL_OUTLET_QUERY = gql`
      query Outlets($staff_id:Int, $service_id:Int) {
        outlets(staff_id:$staff_id, service_id:$service_id) {
          id
          name
          staff_outlet{
            outlet_id
          }
          service_outlet{
            outlet_id
          }
          image_id
          address1
          address2
          address3
          city
          state
          postcode
          country
          longitude
          latitude
          contact
          email
          fax
          type
        }
      }
  `
  const GET_ALL_SESSIONS_QUERY = gql`
    query Sessions($date:String, $time:String) {
      sessions(date:$date, time:$time){
        date
        day
        from_time
        to_time
        off_day
      }
    }
  `
  const GET_ALL_STAFF_QUERY = gql`
      query staffs($outlet_id:Int, $date:String, $time:String, $service_id:Int) {
        staffs(outlet_id:$outlet_id, date:$date, time:$time, service_id:$service_id) {
          id
          fullname
          staff_outlet{
            outlet_id
          }
          staff_service{
            service_id
          }
          staff_roster{
            date
            day
            from_time
            to_time
            break_from_time
            break_to_time
            off_day
          }
        }
      }
  `
  const GET_ALL_SERVICE_QUERY = gql`
      query Services($service_type_id:Int, $outlet_id:Int) {
        services(service_type_id:$service_type_id, outlet_id:$outlet_id) {
          id
          name
          price
          duration
          service_outlet{
            service_id
          }
          image_id
        }
      }
  `
  const GET_ALL_TAX = gql`
      query getAllTax {
        getAllTax {
          id
          name
          amount
        tax_sales {
          id
          sales_id
          tax_id
          amount
        }
      }
    }
  `
  const GET_ALL_SERVICE_TYPE_QUERY = gql`
      query ServiceTypes {
        serviceTypes {
          id
          name
        }
      }
  `
  const GET_ALL_RESOURCE_TYPE_QUERY = gql`
      query ResourceTypes {
        resourceTypes {
          id
          name
          price
        }
      }
  `
  const GET_ALL_RESOURCES_QUERY = gql`
    query Resources($date:String, $time:String, $outlet_id:Int){
      resources(date:$date, time:$time, outlet_id:$outlet_id) {
        id
        name
        resource_type_id
        resource_schedule{
          date
          day
          from_time
          to_time
          break_from_time
          break_to_time
          off_day
        }
        outlet_id
      }
    }
  `
  const GET_ALL_BOOKINGS_QUERY = gql`
    query Bookings($service_id:Int, $resource_type_id:Int, $booked_date:String, $status:BookingStatus, $outlet_id:Int, $staff_id:Int){
      bookings(service_id:$service_id, resource_type_id:$resource_type_id, booked_date:$booked_date, status:$status, outlet_id:$outlet_id, staff_id:$staff_id){
        id
        service_id
        resource_type_id
        booked_date
        status
        outlet_id
        staff_id
        from_time
        duration
      }
    }
  `
  const GET_CUSTOM_PRICE_QUERY = gql`
    query customprice($service_id:Int, $resource_type_id:Int, $date:String){
      customprice(service_id:$service_id, resource_type_id:$resource_type_id, date:$date){
        date
        day
        service_id
        resource_type_id
        amount
      }
    }
  `
  const GET_OUTLET_SESSION_QUERY = gql`
    query outletsession($outlet_id:Int, $date:String){
      outletSessions(outlet_id:$outlet_id, date:$date){
        from_time
        to_time
        outlet_sessions{
          outlet_id
        }
      }
    }
  `
  const GET_SERVICE_QUERY = gql`
      query Service($id: ID!) {
        serviceById(id: $id) {
            name
            description
            price
            duration
            color
            price_type
            service_type_id
            padding_time
            image_id
            service_outlet{
              outlet_id
            }
            staff_service{
              staff_id
            }
            custom_price{
              day
              date
              amount
              description
            }
        }
      }
  `
  export default {
    name: 'OnlineBooking',

		components:{
      Datepicker,
      Topbar
    },

    data () {
      var now = new Date()
      var today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      var minDate = new Date(today)

      return {
        min: minDate,
        current_page: 1,
        steps: 7,
				settings:[],
        bookingflow:[],

				bookingById: {
          staff_id: '',
          outlet_id: '',
          resource_type_id: '',
          resource_type_name: '',
          service_id: '',
          service_type_id:'',
          service_name: '',
          booked_date: '',
          from_time: '',
          duration: '',
          number_of_people: '',
          status: '',
          user_contact: '',
          user_fullname: '',
        },
        user:{
          package_transaction: [],
          all_package_transaction: [],
        },
        groupedSuitablePackages: [],
        filteredPackages: [],
        suitablePackageList: [],
        selectPkgVoucherDialog: false,
        selectedPackage:null,
        outlets: [],
        sessions: [],
        staffs: [],
        processed_staffs: [],
        processed_outlets: [],
        services: [],
        service_id: 0,
        service_type_id: [],
        serviceTypes: [],
        processed_services: [],
        resourceTypes: [],
        resources: [],
        processed_resource_types: [],
        bookings: [],
        customprice: [],
        outletSessions: [],
        menu2: false,
        timeslots:[],
        from_time:"09:00",
        to_time:"22:00",
        type:"service",
        price:0.00,
        servicePrice:{
          subtotal: "0.00",
          total: "0.00",
          totalTax:"",
          taxList:[],
          subtotalTax:"",
        },
        disabledDates: {
          to: new Date(Date.now() - 8640000),
          days: [],
          dates:[],
        },
        alert:{
          show:false,
          message:''
        },
        show: true,
        required:false,
        outlet_image:'',
        service_image:'',
        invalidPhone: false,
        newUser:false,
        showNameAndPw:false, 
        showPassword: false,
        phoneNumber:'',
        password:"",
        counterLength: 12,
        getAllTax: [],
        showAlert: false,
        outletInformation:{
          fullAddress:'',
          contact:'',
          email:'',
        },
        image:"/img/logo.jpeg",
        online_package: false
      }
    },

		apollo: {
      settings: {
        query: GET_SETTINGS_QUERY,
        result(){
          this.slot_duration = this.settings.slot_duration
          this.online_package = this.settings.online_package
          if(this.settings.image_id != 0 && this.settings.image_id != null){
            const GET_IMAGE_QUERY = gql`
              query imageById($id: ID!) {
                  imageById(id:$id)  {
                    id
                    type
                    filename
                  }
              }
            `
            this.$apollo.query(
              { 
                query: GET_IMAGE_QUERY, 
                variables:{
                  id: this.settings.image_id * 1
                },
              }
            ).then(result => {
              if(result.data.imageById != null){ 
                this.image = "https://prod-app360.sfo3.digitaloceanspaces.com/"+result.data.imageById.filename
              } else {
                console.log("Image not found")
              }
            });
          }
        }
      },
      getAllTax: {
        query: GET_ALL_TAX,
        pollInterval: 300,
        fetchPolicy: "network-only"
      },
			outlets: {
        query: GET_ALL_OUTLET_QUERY,
        // pollInterval: 300,
        variables (){
          return {
            staff_id: this.bookingById.staff_id*1,
            service_id: this.bookingById.service_id*1
          }
        },
        result (){
          this.processed_outlets = []
          for(let i=0; i<this.outlets.length; i++){
            if(this.outlets[i].staff_outlet.length > 0 && this.outlets[i].service_outlet.length > 0){
              this.processed_outlets.push(this.outlets[i])
            }
          }
        }
      },
      sessions: {
        query: GET_ALL_SESSIONS_QUERY,
      },
      staffs: {
        query: GET_ALL_STAFF_QUERY,
        // pollInterval: 300,
        variables (){
          var selectedDate = null
          if(this.bookingById.booked_date){
            selectedDate = new Date(this.bookingById.booked_date).toISOString().substring(0, 10)
          }
          return {
            outlet_id: this.bookingById.outlet_id? this.bookingById.outlet_id * 1:this.outlet_id*1,
            service_id: this.bookingById.service_id && this.bookingById.service_id.id?this.bookingById.service_id.id*1:null,
            date: selectedDate,
            time: this.bookingById.from_time?this.bookingById.from_time:null
          }
        },
        result(){
          for(let i=0; i<this.staffs.length; i++){
            if(this.staffs[i].id == 1){
              this.staffs.splice(i, 1)
              break
            }
          }
          this.processed_staffs = []
          for(let i=0; i<this.staffs.length; i++){
            var off_day = false
            if(this.staffs[i].staff_outlet.length > 0 && this.staffs[i].staff_service.length > 0 && this.staffs[i].staff_roster.length > 0){
              for(let x=0; x<this.staffs[i].staff_roster.length; x++){
                if(new Date(this.staffs[i].staff_roster[x].date*1).setHours(0,0,0,0) == new Date(this.bookingById.booked_date).setHours(0,0,0,0)){
                  if(this.staffs[i].staff_roster[x].off_day == 1){
                    off_day = true
                  }
                  break
                }
              }
              if(!off_day){
                this.processed_staffs.push(this.staffs[i])
              }
            }
          }
          if(this.processed_staffs && this.processed_staffs.length === 0){
            this.bookingById.staff_id = ""
          }
        }
      },
      services: {
        query: GET_ALL_SERVICE_QUERY,
        // pollInterval: 300,
        variables (){
          return {
            service_type_id: this.bookingById.service_type_id*1,
            outlet_id: this.bookingById.outlet_id*1
          }
        },
        result (){
          this.processed_services = []
          for(let i=0; i<this.services.length; i++){
            if(this.services[i].service_outlet.length > 0){
              this.processed_services.push(this.services[i])
            }
          }
          this.services = this.processed_services
          this.processedServices()
        }
      },
      serviceTypes: {
        query: GET_ALL_SERVICE_TYPE_QUERY,
      },
      resourceTypes: {
        query: GET_ALL_RESOURCE_TYPE_QUERY,
      },
      resources: {
        variables (){
           var selectedDate = null
          if(this.bookingById.booked_date){
            selectedDate = new Date(this.bookingById.booked_date).toISOString().substring(0, 10)
          }
          return {
            outlet_id: this.bookingById.outlet_id*1,
            date: selectedDate,
            time: this.bookingById.from_time
          }
        },
        query: GET_ALL_RESOURCES_QUERY,
        result() {
          this.processedResourceTypes()
        }
      },
      bookings: {
        variables() {
          // var resource_type_id = null
          // if(this.bookingById.resource_type_id){
          //   resource_type_id = this.bookingById.resource_type_id.id
          // }
          var selectedDate = null
          if(this.bookingById.booked_date){
            selectedDate = new Date(this.bookingById.booked_date).toISOString().substring(0, 10)
          }
          return {
            status: "booked",
            booked_date: selectedDate, 
            resource_type_id: null,
            outlet_id: 0,
          }
        },
        query: GET_ALL_BOOKINGS_QUERY,
        result(error) {
          if(!this.bookingById.booked_date && !this.bookingById.service_id && !this.bookingById.outlet_id && !this.bookingById.staff_id){
            this.bookings = []
          }
          this.processedTimeSlot()
        }
      },
      customprice: {
        variables (){
          var service_id = null
          var resource_type_id = null
          if(this.bookingById.service_id){
            service_id = this.bookingById.service_id*1
          }
          if(this.bookingById.resource_type_id){
            resource_type_id = this.bookingById.resource_type_id*1
          }
          return {
            resource_type_id: resource_type_id,
            service_id: service_id,
            date: this.bookingById.booked_date ? new Date(this.bookingById.booked_date).toISOString().substring(0, 10) : new Date().toISOString().substring(0, 10)
          }

        },
        result(){
          this.calcTotalPrice()
        },
        query: GET_CUSTOM_PRICE_QUERY
      },
      outletSessions: {
        query: GET_OUTLET_SESSION_QUERY,
        // pollInterval: 300,
        variables (){
          var selectedDate = null
          if(this.bookingById.booked_date){
            selectedDate = new Date(this.bookingById.booked_date).toISOString().substring(0, 10)
          }
          return {
            outlet_id: this.bookingById.outlet_id*1,
            date: selectedDate
          }
        },
        result (){
          var session_valid = false
          for(let i=0; i<this.outletSessions.length; i++){
            if(this.outletSessions[i].outlet_sessions.length > 0){
              this.from_time = ("0" + (new Date(1620691200000+this.outletSessions[i].from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.outletSessions[i].from_time*1).getMinutes())).slice(-2)
              this.to_time = ("0" + (new Date(1620691200000+this.outletSessions[i].to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.outletSessions[i].to_time*1).getMinutes())).slice(-2)
              session_valid = true
              break
            }
            if(!session_valid){
              this.from_time = "09:00"
              this.to_time = "22:00"
            }
          }
        }
      },
    },

    watch: {
      steps (val) {
        if (this.current_page > val) {
          this.current_page = val
        }
      },
      'bookingById.service_id'(){
        this.calcTotalPrice()
      },
      "bookingById.user_contact"(value) {
        this.updateCounter(value)
      },
    },

    methods: {
      resetField () {
        this.$refs.form.reset()
        this.bookingById.outlet_id = ''
        this.bookingById.staff_id = ''
        this.bookingById.service_type_id = ''
        this.bookingById.service_id = ''; 
        this.bookingById.resource_type_id = ''
        this.bookingById.booked_date = ''
        this.bookingById.from_time = ''
        this.bookingById.user_fullname = ''
        this.bookingById.user_contact = ''
        this.outlet_image = ''
        this.servicePrice={
          subtotal: "0.00",
          total: "0.00",
          totalTax:"",
          taxList:[],
          subtotalTax:"",
        },
        this. outletInformation={
          fullAddress:'',
          contact:'',
          email:'',
        }
        for (let i = 0; i < this.steps.length; i++) {
          if(i!==0){
            this.steps[i].isDisable = true
            this.steps[i].isOpen = false
          }
          
        }
      },
      disableDate(ymd, date) {
      if (this.disabledDates.days.includes(date.getDay())) {
        return true;
      }
      if (this.disabledDates.dates.includes(ymd)) {
        return true;
      }
        return false;
      },
      findName(field){
        switch (field.type.toLowerCase()) {
        case 'outlet':
          var value = this.processed_outlets.find(o => o.id == this.bookingById.outlet_id)
          return value?value.name:null
        case 'staff':
          var value = this.processed_staffs.find(s => s.id == this.bookingById.staff_id)
          return value?value.fullname:null
        case 'service':
          var value = this.processed_services.find(s => s.id == this.bookingById.service_id)
          return value?`${value.name} (${value.duration} minutes) - RM ${value.price}` : null;
        case 'service type':
          var value = this.serviceTypes.find(s => s.id == this.bookingById.service_type_id)
          return value?value.name:null
        case 'resource type':
          var value = this.processed_resource_types.find(r => r.id == this.bookingById.resource_type_id)
          return value?value.name:null
        case 'resource':
          var value = this.processed_resource_types.find(r => r.id == this.bookingById.resource_type_id)
          return value?value.name:null
        default:
          break;
        }
      },
      nextStep (n, field, required) {
				if ((this.current_page === 1 && n === -1) || (this.current_page === this.steps && n === 1)){
					//do nothing
				}
        else if (n === -1){
          this.resetPreviousSelection(field)
          this.current_page += n
        }
				else {
          this.fieldValidation(field, required)
          if(this.required){
            return
          }
          this.current_page += n
        }
      },
      fieldValidation(field, required) {
        if(field == 'Outlet'){
          if(this.bookingById.outlet_id == '' && required){
            this.required = true
            return
          }
        }
        else if(field == 'Staff'){
          if(this.bookingById.staff_id == '' && required){
            this.required = true
            return
          }
        }
        else if(field == 'Service'){
          if(this.bookingById.service_id == '' && required){
            this.required = true
            return
          }
        }
        else if(field == 'Service Type'){
          if((this.bookingById.service_type_id == null || this.bookingById.service_type_id == '') && required){
            this.required = true
            return
          }
        }
        else if(field == 'Resource Type'){
          if(this.bookingById.resource_type_id == '' && required){
            this.required = true
            return
          }
        }
        else if(field == 'Time'){
          if(this.bookingById.from_time == '' && required){
            this.required = true
            return
          }
        }
        else if(field == 'Info'){
          if((this.bookingById.user_contact == '' || this.bookingById.user_fullname == '') && required){
            this.required = true
            return
          }
        }
        this.required = false
      },
      resetPreviousSelection(field) {
        if(field == 'Outlet'){
          this.bookingById.outlet_id = ''
        }
        else if(field == 'Staff'){
          this.bookingById.staff_id = ''
        }
        else if(field == 'Service'){
          this.bookingById.service_id = ''
        }
        else if(field == 'Service Type'){
          this.bookingById.service_type_id = ''
        }
        else if(field == 'Resource Type'){
          this.bookingById.resource_type_id = ''
        }
        else if(field == 'Time'){
          this.bookingById.from_time = ''
        }
        this.required = false
      },
      dismiss(){
        this.alert.show = false
      },
			validate(){
        var validate = true
          for(let x=0;x<this.steps.length;x++){
            let element = this.steps[x]
            for(let y=0;y<element.value.length;y++){
              const v = element.value[y]
              if(!this.bookingById[v]){
                validate = false
                break;
              }
            } 
          }
          if(!validate){
            this.showAlert = true
            setTimeout(() => {
              this.showAlert = false;
            }, 5000)
          }else if(validate){
            this.createBookings()
          }
      },
      setServiceName(value) {
        for(let i=0; i<this.processed_services.length; i++){
          if(this.processed_services[i].id == this.bookingById.service_id){
            this.bookingById.service_name = this.processed_services[i].name
            this.bookingById.duration = this.processed_services[i].duration
            this.slot_duration = this.processed_services[i].duration *1
            this.processedTimeSlot()
            break
          }
        }
        this.service_id = this.bookingById.service_id*1
      },
      setResourceTypeName(value) {
        this.bookingById.resource_type_name = value.name
      },
      processedServices(){
        if(this.bookingById.staff_id*1 != 0){
          this.service_id = 0
          this.$apollo.queries.staffs.refresh()
          this.processed_services = []
          for(let i=0; i<this.processed_staffs.length; i++){
            if(this.bookingById.staff_id*1 == this.processed_staffs[i].id){
              for(let x=0; x<this.services.length; x++){
                for(let y=0; y<this.processed_staffs[i].staff_service.length; y++){
                  if(this.services[x].id == this.processed_staffs[i].staff_service[y].service_id){
                    this.processed_services.push(this.services[x])
                  }
                }
              }
              break
            }
          }
        }
      },
      processedResourceTypes(){
        this.processed_resource_types = []
        for(let i=0; i<this.resourceTypes.length; i++){
          for(let x=0; x<this.resources.length; x++){
            if(this.resourceTypes[i].id == this.resources[x].resource_type_id && this.resources[x].resource_schedule.length > 0){
              this.processed_resource_types.push(this.resourceTypes[i])
              break
            }
          }
        }
      },
      addMinutes(time, minsToAdd) {
        function D(J){ return (J<10? '0':'') + J;}
        var piece = time.split(':')
        var mins = piece[0]*60 + +piece[1] + +minsToAdd

        return D(mins%(24*60)/60 | 0) + ':' + D(mins%60)
      },
      isTimeOver(time, end_time) {
        const [hours, minutes] = time.split(":").map(Number)
        const [end_hours, end_minutes] = end_time.split(":").map(Number)
        if (hours > end_hours || (hours === end_hours && minutes >= end_minutes)) {
        return true; 
      }
        return false;
      },
      timeSlot(){
        this.timeslots = [];
        var start_time = this.from_time
        var end_time = this.to_time
        this.slot_duration = this.bookingById.service_id
        ?this.services.find(s=>s.id==this.bookingById.service_id).duration
        :this.settings.slot_duration
        while(start_time <= end_time && !this.isTimeOver(start_time, end_time)){
          this.timeslots.push(start_time)
          start_time = this.addMinutes(start_time, this.slot_duration.toString())
        }
      },
      processedTimeSlot(){
        this.timeSlot()
        var formattedTimeSlots = []
        for (let i = 0; i < this.bookings.length; i++) {
          const bookingDate = new Date(this.bookings[i].booked_date * 1);
          const targetDate = new Date(this.bookingById.booked_date);

          if (
            bookingDate.getFullYear() === targetDate.getFullYear() &&
            bookingDate.getMonth() === targetDate.getMonth() &&
            bookingDate.getDate() === targetDate.getDate()
          ) {
            if (
              this.bookingById.staff_id ||
              this.bookingById.resource_type_id
            ) {
              if (
                this.bookingById.staff_id &&
                this.bookings[i].staff_id &&
                this.bookingById.staff_id.toString() !== this.bookings[i].staff_id.toString()
              ) {
                continue;
              }

              if (this.bookingById.resource_type_id && this.bookings[i].service_id) {
                continue;
              }
              var first = new Date(this.bookings[i].booked_date*1)
              var first_timestamp = new Date(1620691200000+this.bookings[i].from_time*1)
              var year = first.getFullYear()
              var month = first.getMonth()+1
              var date = first.getDate()
              var first_hour = first_timestamp.getHours()
              var first_minute = first_timestamp.getMinutes()
              first = new Date(year+'-'+month+'-'+date+' '+first_hour+':'+first_minute)
              first.setHours(first.getUTCHours())
              var second_timestamp = new Date(1620691200000+this.bookings[i].from_time*1 + this.bookings[i].duration*60*1000)
              var second1 = new Date(this.bookings[i].booked_date*1 + this.bookings[i].duration*60*1000)
              var year2 = second1.getFullYear()
              var month2 = second1.getMonth()+1
              var date2 = second1.getDate()
              var second_hour = second_timestamp.getHours()
              var second_minute = second_timestamp.getMinutes()
              var second = new Date(year2+'-'+month2+'-'+date2+' '+second_hour+':'+second_minute)
              second.setHours(second.getUTCHours())
              for (let x = 0; x < this.timeslots.length; x++) {
                const [slotHours, slotMinutes] = this.timeslots[x].split(":").map(Number);
                const timeslotStartTime = new Date(bookingDate);
                timeslotStartTime.setHours(slotHours);
                timeslotStartTime.setMinutes(slotMinutes);
                const timeslotEndTime = new Date(timeslotStartTime.getTime() + (this.slot_duration * 60 * 1000));                
                if (
                  ((timeslotStartTime >= first &&
                  timeslotStartTime < second) ||
                  (timeslotEndTime > first &&
                  timeslotEndTime < second)) &&
                  (
                    this.bookingById.service_id ||
                    this.bookingById.outlet_id ||
                    this.bookingById.staff_id ||
                    this.bookingById.resource_type_id
                  )
                ) {
                  this.timeslots.splice(x, 1);
                  x--
                }
              }
            }
          }
        }
        if(this.bookingById.staff_id){
          const staff_details = this.staffs.find(s => s.id.toString() === this.bookingById.staff_id.toString())
          for (let i = 0; i < staff_details.staff_roster.length; i++) {
            const roster = staff_details.staff_roster[i];
            const breakFromTimeMs = Number(roster.break_from_time);
            const breakToTimeMs = Number(roster.break_to_time);

            // Convert break times to hours and minutes
            const staffBreakStartHour = Math.floor(breakFromTimeMs / (60 * 60 * 1000));
            const staffBreakStartMinute = Math.floor((breakFromTimeMs % (60 * 60 * 1000)) / (60 * 1000));
            const staffBreakEndHour = Math.floor(breakToTimeMs / (60 * 60 * 1000));
            const staffBreakEndMinute = Math.floor((breakToTimeMs % (60 * 60 * 1000)) / (60 * 1000));
            const staffBreakStartTime = new Date(0);
            staffBreakStartTime.setHours(staffBreakStartHour, staffBreakStartMinute, 0, 0);

            const staffBreakEndTime = new Date(0);
            staffBreakEndTime.setHours(staffBreakEndHour, staffBreakEndMinute, 0, 0);
            for (let v = 0; v < this.timeslots.length; v++) {
              const [slotHours, slotMinutes] = this.timeslots[v].split(":").map(Number);
              const timeslotStartTime = new Date(0);
              timeslotStartTime.setHours(slotHours);
              timeslotStartTime.setMinutes(slotMinutes);
              const timeslotEndTime = new Date(timeslotStartTime.getTime() + (this.slot_duration * 60 * 1000));
              // Check if the timeslot falls within the staff's break time
              if (
                (timeslotStartTime >= staffBreakStartTime && timeslotStartTime < staffBreakEndTime) ||
                (timeslotEndTime > staffBreakStartTime && timeslotEndTime <= staffBreakEndTime)
              ) {
                // Remove the timeslot
                this.timeslots.splice(v, 1);
                v--;
              }
            }
          }
        }
        if (!this.timeslots.includes(this.bookingById.from_time)) {
          this.bookingById.from_time = "";
        }
        for (let i = 0; i < this.timeslots.length - 1; i++) {
        const startValue = this.timeslots[i]
        const endValue = this.addMinutes(
          startValue,
          this.slot_duration.toString()
        )

        const formattedStartValue = this.formatTime(startValue)
        const formattedEndValue = this.formatTime(endValue)

        const text = `${formattedStartValue} - ${formattedEndValue}`

        formattedTimeSlots.push({ text, value: startValue })
      }
        this.timeslots = formattedTimeSlots
      },
      formatTime(time) {
      const [hours, minutes] = time.split(":").map(Number)
      const ampm = hours < 12 ? "AM" : "PM"
      const formattedHours = (hours % 12) || 12
      return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes}${ampm}`
      },
      processedDate(){
        var off_dates = []
        var not_available_days = [1,2,3,4,5,6,0]
        for(let i=0; i<this.processed_staffs.length; i++){
          if(this.bookingById.staff_id == this.processed_staffs[i].id){
            for(let x=0; x<this.processed_staffs[i].staff_roster.length; x++){
              if(this.processed_staffs[i].staff_roster[x].date != null){
                if(this.processed_staffs[i].staff_roster[x].off_day == 1){
                  off_dates.push(new Date(this.processed_staffs[i].staff_roster[x].date*1))
                }
              }
              else{
                var day = this.processed_staffs[i].staff_roster[x].day
                if(day == 7){
                  day = 0
                }
                const index = not_available_days.indexOf(day);
                if (index > -1) {
                  not_available_days.splice(index, 1);
                }
              }
            }
          }
        }
        if(not_available_days.length == 7){
          not_available_days = []
        }
        this.disabledDates = {
          to: new Date(Date.now() - 8640000),
          days: not_available_days,
          dates: off_dates
        }
      },
      outletImage(){
        var current_outlet = null;
        for(let i =0; i < this.processed_outlets.length; i++){
          if(this.bookingById.outlet_id == this.processed_outlets[i].id){
           this.outletInformation = current_outlet = this.processed_outlets[i]
          }
        }
        const addressLines = [
            this.outletInformation.name,
            this.outletInformation.address1,
            this.outletInformation.address2,
            this.outletInformation.address3,
            `${this.outletInformation.postcode} ${this.outletInformation.city}`,
            this.outletInformation.state,
            this.outletInformation.country
          ];
          this.outletInformation.fullAddress = addressLines
            .filter(line => line !== '')
            .join(',');

          
        
        if(current_outlet && current_outlet.image_id){
          const GET_IMAGE_QUERY = gql`
            query imageById($id: ID!) {
                imageById(id:$id)  {
                  id
                  type
                  filename
                }
            }
          `
          this.$apollo.query(
            { 
              query: GET_IMAGE_QUERY, 
              variables:{
                id: current_outlet.image_id
              },
            }
          ).then(result => {
            if(result.data.imageById != null){
              var url = "https://prod-app360.sfo3.digitaloceanspaces.com/"+result.data.imageById.filename
              this.outlet_image = url
            } else {
              console.log("Image not found")
            }
          });
        }
        else{
          this.outlet_image = null
        }
      },
      serviceImage(){
        var current_outlet = null;
        for(let i =0; i < this.processed_services.length; i++){
          if(this.bookingById.service_id == this.processed_services[i].id){
            current_outlet = this.processed_services[i]
          }
        }
        if(current_outlet && current_outlet.image_id){
          const GET_IMAGE_QUERY = gql`
            query imageById($id: ID!) {
                imageById(id:$id)  {
                  id
                  type
                  filename
                }
            }
          `
          this.$apollo.query(
            { 
              query: GET_IMAGE_QUERY, 
              variables:{
                id: current_outlet.image_id
              },
            }
          ).then(result => {
            if(result.data.imageById != null){
              var url = "https://prod-app360.sfo3.digitaloceanspaces.com/"+result.data.imageById.filename
              this.service_image = url
            } else {
              console.log("Image not found")
            }
          });
        }
        else{
          this.service_image = null
        }
      },
      calcTotalPrice(){
        var subtotal = 0
        if(!this.bookingById.service_id)  {
          this.servicePrice.subtotal = subtotal
          this.servicePrice.total =  this.servicePrice.subtotal
          return
        }
        var subtotal = this.services.find(s => s.id *1 == this.bookingById.service_id *1 ).price
        if (this.customprice && this.customprice.length > 0) {
          for (const price of this.customprice) {
            if (price.service_id * 1 === this.bookingById.service_id * 1) {
              subtotal = price.amount * 1;
              break;
            }
          }
        }

        this.servicePrice.subtotal = parseFloat(subtotal).toFixed(2)
        this.servicePrice.total = parseFloat(this.servicePrice.subtotal).toFixed(2)

        if(this.bookingById.package_id){
          subtotal = 0
          this.servicePrice.subtotal = "0.00"
          this.servicePrice.total = "0.00"
        }
        if(this.getAllTax.length > 0) {
          this.servicePrice.taxList = []
          this.servicePrice.totalTax = 0
          this.getAllTax.forEach(t=>{
            var taxAmount = {
              id : t.id,
              name: t.name,
              amount: (subtotal * t.amount / 100).toFixed(2),
              percentage: t.amount,
            }
            this.servicePrice.taxList.push(taxAmount)
            this.servicePrice.totalTax += taxAmount.amount * 1
          })
          this.servicePrice.total = this.servicePrice.subtotalTax =  parseFloat(this.servicePrice.subtotal * 1  + this.servicePrice.totalTax * 1).toFixed(2)
        }
        this.handleBlur()
      },
      handleBlur() {
        if(this.bookingById.service_id) {
          const parsedValue = parseFloat(this.servicePrice.total);
          if (!isNaN(parsedValue) && parsedValue > 0 ) {
            if( this.servicePrice.subtotalTax * 1 > this.servicePrice.total * 1){
              this.servicePrice.discount = (this.servicePrice.subtotalTax * 1 - this.servicePrice.total * 1).toFixed(2)
            }else{
              this.servicePrice.discount = "0.00"
            }
            this.servicePrice.total = parsedValue.toFixed(2);
        } else {
            this.servicePrice.total = (0.00).toFixed(2)
          }
        }
      },
      openField(n){
        if(!this.steps[n].isDisable)this.steps[n].isOpen=!this.steps[n].isOpen  
        this.$forceUpdate()
      },
      validateField(field,n){
        var isEmpty = false

        field.value.forEach(v => {
          if(!this.bookingById[v]){
            isEmpty = true
            return
          }
        })
        if(n+1 < this.steps.length && !this.steps[n+1].isDisable){
          for(let i=n+1;i<this.steps.length;i++){
            let element = this.steps[i]
            for(let j=0;j<element.value.length;j++){
              const v = element.value[j]
              if(element.type.toLowerCase() != "info"){
                this.bookingById[v]=null
              } 
            } 
            element.isDisable = true
            element.isOpen = false
            }
          }
        if(!isEmpty){
          if (n === this.steps.length - 1) {
            this.steps.forEach((step, index) => {
              if (index === this.steps.length - 1) {
                step.isOpen = true;
              } else {
                step.isOpen = false; 
              }
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
              });
            });
          }else{
            this.steps[n+1].isDisable = false
          this.steps[n+1].isOpen = true
          }
          this.$forceUpdate()
        }
        this.$forceUpdate()
      },
      updateCounter(value) {
        const number = value.trim();
        if (number.startsWith("60")) {
          this.counterLength = 12;
        } else if (number.startsWith("0")) {
          this.counterLength = 11;
        } else if (number.startsWith("1")) {
          this.counterLength = 10;
        } else {
          this.counterLength = 12;
        }
      },
      parsePhoneNumber(phoneNumber) {
        const countryCode = "60";
        if (
          !phoneNumber ||
          !countryCode ||
          phoneNumber.length < 1 ||
          countryCode.length < 2
        )
          return phoneNumber;

        const normalizedNumber = phoneNumber.replace(/\D/g, ""); 

        if (normalizedNumber.startsWith(countryCode)) {
          return normalizedNumber; 
        } else if (normalizedNumber.startsWith("0")) {
          return countryCode + normalizedNumber.slice(1); 
        }
          return countryCode + normalizedNumber; 
      },
      checkUserPhoneNumberExists(field,n){
        var userContact = this.parsePhoneNumber(this.phoneNumber)
        this.invalidPhone = userContact.length < 11
        if(userContact.length < 11) return
        const CHECK_PHONE_NUMBER_EXISTS = gql`
          query CheckPhoneNumberExists($user_contact: String!) {
            checkPhoneNumberExists(user_contact: $user_contact)
          }
        `;
        
        this.$apollo.query(
          { 
              query: CHECK_PHONE_NUMBER_EXISTS, 
              variables:{
                user_contact: userContact
              },
              fetchPolicy: 'network-only'
          }
        ).then(result => {
          const exists = result.data.checkPhoneNumberExists;
          if(!exists){
            this.newUser = true
            this.showNameAndPw = true
            this.phoneNumber = userContact
            this.bookingById.user_fullname = ''
            this.password = ''
          }else{
            const GET_USER_QUERY = gql`
              query User($user_contact: String!) {
                userByContact(user_contact: $user_contact) {
                  id
                  user_id_external
                  user_fullname
                  user_contact
                }
              }
            `
            this.$apollo.query(
          { 
            query: GET_USER_QUERY, 
              variables:{
                user_contact: userContact
              },
          }).then(async result => {
            this.newUser = false
            this.showNameAndPw = false
            this.bookingById.user_fullname = result.data.userByContact.user_fullname
            this.bookingById.user_contact= result.data.userByContact.user_contact
            this.bookingById.user_id = await result.data.userByContact.id
            this.validateField(field,n)
            if(this.online_package){
              const GET_USERBYID_QUERY = gql`
                query User($id: ID!) {
                  userById(id: $id) {
                    id
                    Package_Transaction{
                      id
                      Package{
                          package_name
                      }
                      used_at
                      expiration_date
                      created_at
                      sales_id
                      resource_Type{
                          id
                          name
                      }
                      service_Type{
                          id
                          name
                      }
                      resource{
                          id
                          name
                      }
                      service{
                          id
                          name
                      }
                    }
                  }
                }
              `
              this.$apollo.query(
              { 
                  query: GET_USERBYID_QUERY, 
                  fetchPolicy: 'network-only',
                  variables:{
                    id: this.bookingById.user_id * 1
                  },
              }).then(result => {
                if(result.data.userById.Package_Transaction.length > 0){
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  const tomorrow = new Date();
                  tomorrow.setDate(tomorrow.getDate() + 1);
                  tomorrow.setHours(0, 0, 0, 0);
                  const resultPackage = [...result.data.userById.Package_Transaction]
                  this.user.package_transaction = resultPackage.filter(pt => {
                    const isUsed = pt.used_at === null;
                    const expirationDate = pt.expiration_date ? new Date(parseInt(pt.expiration_date)) : null;
                    const isExpired = expirationDate && expirationDate < tomorrow;
                    if (isUsed && (!expirationDate || !isExpired)) {
                      return pt;
                    }
                  });
                  this.user.all_package_transaction = [...this.user.package_transaction]
                  this.filterPackage()
                  this.user.package_transaction_length = this.user.package_transaction.length
                }else{
                  this.user.package_transaction = []
                  this.user.all_package_transaction = []
                }
              })
            }
          })
        }
      })
      },
      createUser(field,n){
        if(this.newUser){
          const CREATE_USER_QUERY = gql`
            mutation createUser(
              $user_id_external: String
              $user_fullname: String
              $user_contact: String!
              $password: String
            ) {
              createUser(
                user_id_external: $user_id_external
                user_fullname: $user_fullname
                user_contact: $user_contact
                password: $password
              ) {
                id
                user_id_external
                user_fullname
                user_contact
                password
              }
            }
          `;
          var userContact = this.parsePhoneNumber(this.phoneNumber)
          var passwordHash = require("password-hash");
          this.$apollo.mutate({
            mutation: CREATE_USER_QUERY,
            variables: {
              user_id_external: null,
              user_fullname: this.bookingById.user_fullname? this.bookingById.user_fullname : userContact,
              user_contact: userContact,
              password: passwordHash.generate(this.password?this.password:userContact.slice(-6)),
            },
          }).then(async result => {
            var userNew = result.data.createUser
            this.bookingById.user_fullname = userNew.user_fullname
            this.bookingById.user_id = userNew.id
            this.bookingById.user_contact = userNew.user_contact
            this.user_contact = ""
            this.showNameAndPw = false
            this.newUser = false
            await this.validateField(field,n)
          })
          .catch(error => {
            console.log("password",JSON.stringify(error, null, 2));
          })
        }
      },
      filterPackage(){
        let groupedPackages = []
        const groups = {};

        this.user.package_transaction.forEach((pkg) => {
            const packageName = pkg.Package.package_name;
            const salesId = pkg.Package.sales_id;
            const id = pkg.id;
            const createdDate = new Date(parseInt(pkg.created_at)).toLocaleDateString();
            const serviceType = pkg.service_Type ? { id: pkg.service_Type.id, name: pkg.service_Type.name } :null;
            const service = pkg.service ? { id: pkg.service.id, name: pkg.service.name } :null;
            const resource = pkg.resource ? { id: pkg.resource.id, name: pkg.resource.name } :null;
            const resourceType = pkg.resource_Type ? { id: pkg.resource_Type.id, name: pkg.resource_Type.name } :null;
            const expiration_date = pkg.expiration_date ? new Date(parseInt(pkg.expiration_date)).toLocaleDateString() : null;

            const key = `${packageName}_${createdDate}_${expiration_date}_${salesId}`;
            const item = {
              id,
              serviceType,
              service,
              resource,
              resourceType,
            };

            if (!groups[key]) {
              groups[key] = {
                packageName,
                createdDate,
                expiration_date,
                items: [item],
              };
            } else {
              groups[key].items.push(item);
            }
          });

          groupedPackages = Object.values(groups);
          groupedPackages.forEach((group) => {
            group.statistics = {}
            group.items.forEach((item) => {
              const serviceType = item.serviceType && item.serviceType.name ? item.serviceType.name : null;
              const service = item.service && item.service.name ? item.service.name : null;
              const resource = item.resource && item.resource.name ? item.resource.name : null;
              const resourceType = item.resourceType && item.resourceType.name ? item.resourceType.name : null;


              let key = '';

              if (serviceType && service) {
                key = `${serviceType}(${service})`;
              } else if (serviceType) {
                key = `${serviceType}`;
              }

              if (resource) {
                key = `${resourceType}(${resource})`;
              } else if (resourceType) {
                key = `${resourceType}`;
              }
              if (key) {
                item.key = key
                if (!group.statistics[key]) {
                  group.statistics[key] = 0;
                }
                group.statistics[key] += 1;
              }
            });
          });
        this.filteredPackages = groupedPackages.sort((a, b) => {
          const dateA = new Date(a.expiration_date);
          const dateB = new Date(b.expiration_date);
          return dateB - dateA;
        });
        this.usePackageDiscount()
      },
      usePackageDiscount(){
        if(!this.bookingById.user_id) return
        this.suitablePackageList = []
        if (this.bookingById.service_id){
          this.$apollo.query({
            query: GET_SERVICE_QUERY,
            variables:{
              id: this.bookingById.service_id * 1,
            }
          }).then((res)=>{
              for (let i = 0; i < this.user.package_transaction.length; i++) {
              const voucher = this.user.package_transaction[i];
              if (voucher.service && voucher.service.id == this.bookingById.service_id) {
                this.suitablePackageList.push(voucher);
              }
            }

            for (let i = 0; i < this.user.package_transaction.length; i++) {
              const voucher = this.user.package_transaction[i];
              if (voucher.service_Type && (!voucher.service || !voucher.service.id) && voucher.service_Type.id == res.data.serviceById.service_type_id) {
                this.suitablePackageList.push(voucher);
              }
            }
              this.processSuitablePackages()
          })
          } else{
            for (let i = 0; i < this.user.package_transaction.length; i++) {
              const voucher = this.user.package_transaction[i];
              if (voucher.resource && voucher.resource.id == booking.resource_type_id.resource.id) {
                this.suitablePackageList.push(voucher);
              }
            }
            for (let i = 0; i < this.user.package_transaction.length; i++) {
              const voucher = this.user.package_transaction[i];
              if (voucher.resource_Type && !voucher.resource && voucher.resource_Type.id == booking.resource_type_id.id) {
                this.suitablePackageList.push(voucher);
              }
            }
            this.processSuitablePackages();
        }
      },
      processSuitablePackages() {
        if (this.suitablePackageList.length === 0) {
        } else {
          const groups = {};

          for (const pkg of this.suitablePackageList) {
            const expiry_date = new Date(pkg.expiration_date * 1).toLocaleDateString();
            const groupName = `${pkg.Package.package_name}_${pkg.sales_id}_${expiry_date}`;

            if (!groups[groupName]) {
              groups[groupName] = {
                packageName: pkg.Package.package_name,
                packageExpiry: expiry_date,
                packagelist: [],
              };
            }

            groups[groupName].packagelist.push(pkg);
          }

          this.groupedSuitablePackages = Object.values(groups);
        }
      },
      remainVoucher(){
        this.$refs['my-modal'].hide()
        this.selectedPackage = {}
      },
      useVoucher(){
        this.bookingById.package_id = this.selectedPackage.id;
        this.bookingById.package_name = this.selectedPackage.Package.package_name
        this.bookingById.suitablePackage = true
        this.$forceUpdate();
        this.selectedPackage = {}
        this.calcTotalPrice()
        this.$refs['my-modal'].hide()
      },
      removeVoucher(){
        this.bookingById.package_id = "";
        this.bookingById.package_name = ""
        this.bookingById.suitablePackage = false
        this.$forceUpdate();
        this.selectedPackage = {}
        this.calcTotalPrice()
      },
      formatDate(timestamp) {
        if (!timestamp) return "Permenant"; // Handle cases where expiration_date is not provided
        const date = new Date(parseInt(timestamp));
        return date.toLocaleDateString();
      },
      createBookings() {
      const CREATE_BOOKING_QUERY = gql`
            mutation createBooking($staff_id: Int, $outlet_id:Int!, $resource_type_id:Int, $resource_type_name:String, $service_id:Int, $service_name:String, $booked_date:String!, $from_time:String!, $duration:Int!, $number_of_people:Int!, $status:BookingStatus, $booking_from:String, $amount:Float!, $booking_no:String!, $user_contact:String, $user_fullname:String, $user_id: Int){
              createBooking(staff_id:$staff_id, outlet_id:$outlet_id, resource_type_id:$resource_type_id, resource_type_name:$resource_type_name, service_id:$service_id, service_name:$service_name, booked_date:$booked_date, from_time:$from_time, duration:$duration, number_of_people:$number_of_people, status:$status, booking_from:$booking_from, amount:$amount, booking_no:$booking_no, user_contact:$user_contact, user_fullname:$user_fullname, user_id:$user_id){
                id
                booking_no
                booked_date
                duration
                service_name
                resource_type_name
                user_id
              }
            }
          `
      this.$apollo.mutate(
        {
          mutation: CREATE_BOOKING_QUERY,
          variables: {
            created_by_staff_id: null,
            staff_id: this.bookingById.staff_id * 1,
            outlet_id: this.bookingById.outlet_id * 1,
            resource_type_id: this.bookingById.resource_type_id ? this.bookingById.resource_type_id * 1 : null,
            resource_type_name: this.bookingById.resource_type_name,
            service_id: this.bookingById.service_id ? this.bookingById.service_id * 1 : null,
            service_name: this.bookingById.service_name,
            booked_date: this.bookingById.booked_date,
            from_time: this.bookingById.from_time,
            duration: this.bookingById.duration * 1,
            number_of_people: this.bookingById.number_of_people * 1,
            status: 'booked',
            booking_from: 'onlinebooking',
            amount: parseFloat(this.servicePrice.total),
            booking_no: 'B001',
            user_id: this.bookingById.user_id*1,
            user_contact: this.bookingById.user_contact,
            user_fullname: this.bookingById.user_fullname,
            package_transaction_id: this.bookingById.package_id?this.bookingById.package_id *1:null,
            customCommission: null
          },
        }
      )
        .catch(error => {
          console.log(JSON.stringify(error, null, 2));
        })
        .then(result => {
          if(this.bookingById.package_id){
            const UPDATE_PACKAGE_TRANSACTION_USED_AT = gql`
              mutation UpdatePackageTransactionUsedAt($id: ID!) {
                updatePackageTransactionUsedAt(id: $id) {
                  id
                  used_at
                }
              }
            `;

            this.$apollo.mutate({
              mutation: UPDATE_PACKAGE_TRANSACTION_USED_AT,
              variables: {
                id: this.bookingById.package_id * 1 
              }
            }).then(result=>
            {
              console.log("update",result)
            }
            ).catch((error) => {
            console.log(JSON.stringify(error, null, 2));
            });
          }
          if (result && result.data.createBooking != null) {
            var bookingIdList = []
            let bookingID = result.data.createBooking.id
            bookingIdList.push(bookingID)
            const CREATE_SALES = gql`
                mutation CreateSales($sales_type: salesType!, $status: payStatus!, $package_id: Json, $booking_id: Json, $amount: Float!, $user_id: Int!, $outlet_id: Int!, $staff_id: Int, $online_booking: Boolean, $split_payment: Boolean, $tax_amount: Float) {
                  createSales(sales_type: $sales_type, status: $status, package_id: $package_id, booking_id: $booking_id, amount: $amount, user_id: $user_id, outlet_id: $outlet_id, staff_id: $staff_id, online_booking: $online_booking, split_payment: $split_payment, tax_amount: $tax_amount ) {
                    id
                    sales_type
                    status
                    package_id
                    booking_id
                    amount
                    user_id
                    outlet_id
                    staff_id
                    online_booking
                    invoice_no
                    created_at
                    updated_at
                    deleted_at
                    voided_at
                    split_payment
                    }
                  }
                `;
            this.$apollo.mutate({
              mutation: CREATE_SALES,
              variables: {
                sales_type: 'booking',
                status: "pending",
                package_id: JSON.stringify(null),
                booking_id: JSON.stringify(bookingIdList),
                amount: this.servicePrice.total * 1,
                user_id: parseInt(this.bookingById.user_id),
                outlet_id: parseInt(this.bookingById.outlet_id),
                staff_id: null,
                online_booking: true,
                split_payment: false,
                tax_amount: this.servicePrice.totalTax ? parseFloat(this.servicePrice.totalTax) : null
              },
            }).then(async (result) => {
              let createdSalesId = result.data.createSales.id;
              const taxMutations = [];

              if (this.servicePrice.taxList.length > 0) {
                const CREATE_TAX_SALES = gql`
                  mutation createTaxSales($tax_id: Int!, $sales_id: Int!, $amount: Float!) {
                    createTaxSales(tax_id: $tax_id, sales_id: $sales_id, amount: $amount) {
                      id
                    }
                  }
                `;

                for (let i = 0; i < this.servicePrice.taxList.length; i++) {
                  const t = this.servicePrice.taxList[i];
                  if (t.amount > 0) {
                    taxMutations.push(
                      this.$apollo
                        .mutate({
                          mutation: CREATE_TAX_SALES,
                          variables: {
                            tax_id: parseInt(t.id),
                            sales_id: parseInt(createdSalesId),
                            amount: parseFloat(t.amount * 1),
                          },
                        })
                        .catch((error) => {
                          console.log("tax", JSON.stringify(error, null, 2));
                        })
                    );
                  }
                }
              }

              Promise.all(taxMutations)
                .then(() => {
                  // All tax mutations have completed here
                  const SEND_MESSAGE = gql`
                    mutation SendMessage($userContact: String!, $message: String!) {
                      sendMessage(userContact: $userContact, message: $message) {
                        success
                        message
                      }
                    }
                  `;

                  const bookingMessage = `Booking Confirmation Received. \n${this.bookingById.resource_type_name?this.bookingById.resource_type_name:this.bookingById.service_name}(${this.bookingById.resource_type_name?this.bookingById.user_fullname:this.staffs.find(s=>s.id == this.bookingById.staff_id).fullname})\nDate: ${this.bookingById.booked_date}\nTime: ${this.bookingById.from_time}\nOutlet: ${this.outlets.find(s=>s.id == this.bookingById.outlet_id).name}`

                  return this.$apollo
                    .mutate({
                      mutation: SEND_MESSAGE,
                      variables: {
                        userContact: this.bookingById.user_contact,
                        message: bookingMessage,
                      },
                    })
                    .then(async () => {
                      // Route redirection here
                      this.$router.push({
                        name: "Confirmed Online Booking",
                        params: {
                          booking_id: bookingID,
                        },
                      });
                    })
                    .catch((error) => {
                      console.log("send", JSON.stringify(error, null, 2));
                    });
                })
                .catch((error) => {
                  console.log("One or more tax mutations failed:", error);
                  // Handle the case where one or more tax mutations fail here
                });
            })
          }
        })
      },
    },
    created() {
      this.$apollo.query(
        {
          query: GET_BOOKING_FLOW_QUERY,
        }
      ).then(async result => {
        var bookingFlow = result.data.bookingflow
        this.bookingflow = bookingFlow
        this.steps = bookingFlow
        for (let i = 0; i < this.steps.length; i++) {
          const element = this.steps[i];
          if (i == 0) {
            element.isOpen = true;
            element.isDisable = false;
          } else {
            element.isOpen = false;
            element.isDisable = true;
          }
          element.value = [];
          switch (element.type.toLowerCase()) {
            case 'outlet':
              element.value.push('outlet_id');
              break;
            case 'staff':
              element.value.push('staff_id');
              break;
            case 'service type':
              element.value.push('service_type_id');
              break;
            case 'service':
              element.value.push('service_id');
              break;
            case 'resource_type':
              element.value.push('resource_type_id');
              break;
            case 'date':
              element.value.push('booked_date');
              break;
            case 'time':
              element.value.push('from_time');
              break;
            case 'info':
              element.value.push('user_fullname');
              element.value.push('user_contact');
              break;
            default:
              break;
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    }
</script>