<template>
  <b-navbar toggleable="lg" type="dark" variant="primary" class="navbar-horizontal">
    <b-navbar-brand href="/">Online Booking Form</b-navbar-brand><b-badge variant="primary">v0.3.0</b-badge>
    <!-- Right aligned nav items -->
    <b-navbar-nav class="ml-auto">
      <b-nav-form>
          <li class="nav-item">
              <a style="color:white" @click="AppointmentTracking">
                  <span class="">Appointment Tracking</span>
              </a>
          </li>
      </b-nav-form>

    </b-navbar-nav>
  </b-navbar>
</template>

<script>
  export default {
    methods:{
      AppointmentTracking() {
        this.$router.push({ path: '/tracking' })
      },
    }
  }
</script>