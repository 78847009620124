<template>
  <v-app>
  <Topbar></Topbar>
  <div
    style="background-image: url('/img/form-banner.jpeg');
    height: 200px;
    z-index: 2;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #eee;"
  ></div>

  <div v-if="$data.$screenSize.width > 765">
    <b-img src="/img/logo.jpeg" rounded="sm" 
      style="width:150px;height:150px;
        position: absolute;
        left: 50px;
        border-radius: 7px;
        z-index: 3;
        bottom: 0;
        top:180px;
        border: solid 1px #eee;
        background: #fff;"
    />

    <div style="position:absolute; left:250px; top: 270px">
      <p class="h1">Oasis Beauty Spa</p>
      <p>Beauty and Wellness Salon</p>
    </div>
  </div>

  <div v-else>
    <b-img src="/img/logo.jpeg" rounded="sm" 
      style="width:120px;height:120px;
        position: absolute;
        border-radius: 7px;
        z-index: 3;
        bottom: 0;
        top:200px;
        border: solid 1px #eee;
        background: #fff;" 
      :style="{left:($data.$screenSize.width-120)/2 + 'px'}"
    />

    <div 
      style="text-align:center; padding-top:70px"
    >
      <p class="h1">Oasis Beauty Spa</p>
      <p>Beauty and Wellness Salon</p>
    </div>
  </div>
  <div v-if="$data.$screenSize.width > 765" style="padding-top:100px"></div>
  <div style="margin:20px">
     <b-card>
        <b-card-text>
           Your Booking has been confirmed. You may close the web page. <v-btn :to="{name: 'Online Booking'}">Book again</v-btn>
        </b-card-text>
     </b-card>
     <br/>
     <b-card
        title="Appointment Details"
        class="mb-2"
        v-if="details"
      >
        <b-card-text>
          Service: {{details.name}} <br/>
          Staff: {{details.staff}} <br/>
          Outlet: {{details.outlet}} <br/>
          Date: {{details.date}} <br/>
          Time: {{("0" + details.start.getHours()).slice(-2) + ":" + ("0" + details.start.getMinutes()).slice(-2)}} - {{("0" + details.end.getHours()).slice(-2) + ":" + ("0" + details.end.getUTCMinutes()).slice(-2)}} <br/>
          Fullname: {{details.user_fullname}} <br/>
          Phone Number: {{details.user_contact}} <br/>
        </b-card-text>
      </b-card>
  </div>
  </v-app>
</template>

<script>
  import gql from 'graphql-tag'
  import Topbar from '/src/custom-components/Topbar'

  const GET_BOOKING_QUERY = gql`
    query Booking($id: ID!) {
      bookingById(id:$id){
        id
        service_id
        resource_type_id
        service_name
        resource_type_name
        booked_date
        status
        outlet_id
        staff_id
        from_time
        duration
        booking_from
        outlet{
          name
        }
        staff{
          fullname
        }
        service{
          name
        }
        created_at
        user_contact
        user_fullname
      }
    }
  `

  export default {
    name: 'OnlineBooking',
    components:{
      Topbar
    },
    data() {
      return {
        bookingById: null,
        details: null
      }
    },
    apollo: {
      bookingById: {
        variables() {
          return{
            id: this.$route.params.booking_id * 1
          }
        },
        query: GET_BOOKING_QUERY,
        result() {
          if(this.bookingById){
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
              "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            var first = new Date(this.bookingById.booked_date*1)
            var first_timestamp = new Date(1620691200000+this.bookingById.from_time*1)
            var year = first.getFullYear()
            var month = first.getMonth()+1
            var date = first.getDate()
            var first_hour = first_timestamp.getHours()
            var first_minute = first_timestamp.getMinutes()
            first = new Date(year+'-'+month+'-'+date+' '+first_hour+':'+first_minute)
            first.setHours(first.getUTCHours())
            var second_timestamp = new Date(1620691200000+this.bookingById.from_time*1 + this.bookingById.duration*60*1000)
            var second_hour = second_timestamp.getHours()
            var second_minute = second_timestamp.getMinutes()
            var second = new Date(year+'-'+month+'-'+date+' '+second_hour+':'+second_minute)
            second.setHours(second.getUTCHours())

            var name = '';
            if(this.bookingById.service){
              name = this.bookingById.service_name
            }
            else{
              name = this.bookingById.resource_type_name
            }
            var date = new Date(this.bookingById.booked_date*1)
            this.details = {
              name: name,
              start: first,
              end: second,
              id: this.bookingById.id,
              date: date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear(),
              time: this.bookingById.from_time,
              outlet: this.bookingById.outlet.name,
              staff: this.bookingById.staff.fullname,
              user_contact: this.bookingById.user_contact,
              user_fullname: this.bookingById.user_fullname
            }
          }
        }
      },
    }
  }
</script>
