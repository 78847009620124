import Vue from "vue";
import Router from "vue-router";

import OnlineBookingForm from './views/OnlineBooking/Form';
import OnlineBookingSuccessForm from './views/OnlineBooking/Success';
import AppointmentTrackingForm from './views/OnlineBooking/AppointmentTracking';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: __dirname,
    routes: [
        {
            path: "",
            name: "Online Booking",
            component: OnlineBookingForm
        },
        
        {
            path: "/success",
            name: "Confirmed Online Booking",
            component: OnlineBookingSuccessForm
        },

        {
            path: "/tracking",
            name: "Appointment Tracking",
            component: AppointmentTrackingForm
        },
    ]
})