<template>
  <v-app>
  <Topbar></Topbar>
  <div
    style="background-image: url('/img/form-banner.jpeg');
    height: 200px;
    z-index: 2;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #eee;"
  ></div>

  <div v-if="$data.$screenSize.width > 765">
    <b-img src="/img/logo.jpeg" rounded="sm" 
      style="width:150px;height:150px;
        position: absolute;
        left: 50px;
        border-radius: 7px;
        z-index: 3;
        bottom: 0;
        top:180px;
        border: solid 1px #eee;
        background: #fff;"
    />

    <div style="position:absolute; left:250px; top: 270px">
      <p class="h1">Oasis Beauty Spa</p>
      <p>Beauty and Wellness Salon</p>
    </div>
  </div>

  <div v-else>
    <b-img src="/img/logo.jpeg" rounded="sm" 
      style="width:120px;height:120px;
        position: absolute;
        border-radius: 7px;
        z-index: 3;
        bottom: 0;
        top:200px;
        border: solid 1px #eee;
        background: #fff;" 
      :style="{left:($data.$screenSize.width-120)/2 + 'px'}"
    />

    <div 
      style="text-align:center; padding-top:70px"
    >
      <p class="h1">Oasis Beauty Spa</p>
      <p>Beauty and Wellness Salon</p>
    </div>
  </div>
  <div v-if="$data.$screenSize.width > 765" style="padding-top:100px"></div>
  <div style="margin:20px">
     <b-card>
        <b-card-text>
          <base-input 
            v-model="user_contact"
            label="Phone number" 
            placeholder="60123456789"
          />
          <base-button 
            color="primary"
            @click="search()"
          >
            Track
          </base-button>
        </b-card-text>
     </b-card>
     <br/>
      <b-card
      v-for="item in result"
      :key="item.id"
      :title="item.name"
      class="mb-2"
    >
      <b-card-text>
        Staff: {{item.staff}} <br/>
        Outlet: {{item.outlet}} <br/>
        Date: {{item.date}} <br/>
        Time: {{("0" + item.start.getHours()).slice(-2) + ":" + ("0" + item.start.getMinutes()).slice(-2)}} - {{("0" + item.end.getHours()).slice(-2) + ":" + ("0" + item.end.getUTCMinutes()).slice(-2)}} <br/>
      </b-card-text>

      <small class="text-muted"></small>
    </b-card>
    <br/>
  </div>
  </v-app>
</template>

<script>
  import gql from 'graphql-tag'
  import Topbar from '/src/custom-components/Topbar'

  const GET_ALL_BOOKINGS_QUERY = gql`
    query Bookings($status:BookingStatus, $user_contact:String){
      bookings(status:$status, user_contact:$user_contact){
        id
        service_id
        resource_type_id
        service_name
        resource_type_name
        booked_date
        status
        outlet_id
        staff_id
        from_time
        duration
        outlet{
          name
        }
        staff{
          fullname
        }
        service{
          name
        }
        created_at
      }
    }
  `
  export default {
    name: 'Appointment Tracking',
    components:{
      Topbar
    },

    data () {
      return {
        user_contact: null,
        bookings: [],
        result: []
      }
    },

		apollo: {
      bookings: {
        variables() {
          return {
            status: "booked",
            user_contact: this.user_contact
          }
        },
        query: GET_ALL_BOOKINGS_QUERY,
      },
    },
    methods: {
      search () {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
				if(this.user_contact == null || this.user_contact == ''){
          this.result = []
        }
        else{
          const events = []
          for (let i = 0; i < this.bookings.length; i++) {
            var first = new Date(this.bookings[i].booked_date*1)
            var first_timestamp = new Date(1620691200000+this.bookings[i].from_time*1)
            var year = first.getFullYear()
            var month = first.getMonth()+1
            var date = first.getDate()
            var first_hour = first_timestamp.getHours()
            var first_minute = first_timestamp.getMinutes()
            first = new Date(year+'-'+month+'-'+date+' '+first_hour+':'+first_minute)
            first.setHours(first.getUTCHours())
            var second_timestamp = new Date(1620691200000+this.bookings[i].from_time*1 + this.bookings[i].duration*60*1000)
            var second_hour = second_timestamp.getHours()
            var second_minute = second_timestamp.getMinutes()
            var second = new Date(year+'-'+month+'-'+date+' '+second_hour+':'+second_minute)
            second.setHours(second.getUTCHours())

            var name = '';
            var color = '';
            var category = '';
            if(this.bookings[i].service){
              if(this.bookings[i].booking_from == 'onlinebooking'){
                name = '[ONLINE] ' + this.bookings[i].service_name
                color = this.bookings[i].service.color
              }
              else{
                name = this.bookings[i].service_name
                color = this.bookings[i].service.color
              }
            }
            else{
              name = this.bookings[i].resource_type_name
              color = this.bookings[i].resource_type.color
            }
            if(this.bookings[i].staff){
              category = this.bookings[i].staff.fullname
            }
            else{
              category = this.bookings[i].resource_type.name
            }
            var date = new Date(this.bookings[i].booked_date*1)
            events.push({
              name: name,
              start: first,
              end: second,
              color: color,
              timed: true,
              id: this.bookings[i].id,
              category: category,
              date: date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear(),
              time: this.bookings[i].from_time,
              outlet: this.bookings[i].outlet.name,
              staff: this.bookings[i].staff.fullname
            })
          }
          this.result = events
        }
        console.log(this.result)
      },
    }
  }
  
</script>
